import * as React from 'react';
import './App.css';
import Nav from './Nav';
function App() {
  return (
    <div className="App container">
      <div className="row">
        <Nav />
      </div>
    </div>
  );
}

export default App;
